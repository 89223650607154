import * as React from "react"
import { graphql } from "gatsby"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { Heading } from "gatsby-interface"
import Layout from "../layout"
import SiteHead from "../head"
import Container from "../layout/container"

export function Head(props) {
  const { page } = props.data
  const { title, description } = page

  return <SiteHead {...props} title={title} description={description} />
}

// this page is currently not using the image field
// but support can be added in the future
export default function ContentfulRichTextPage(props) {
  const { page } = props.data
  const bodyElement = renderRichText(page.body)

  return (
    <Layout>
      <Container
        css={t => ({
          maxWidth: "50em",
          paddingTop: t.space[7],
          paddingBottom: t.space[7],
        })}
      >
        <Heading as="h1">{page.title}</Heading>
        {bodyElement}
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query RichTextPageContent($id: String!) {
    page: contentfulRichTextPage(id: { eq: $id }) {
      id
      title
      slug
      description
      body {
        raw
      }
    }
  }
`
